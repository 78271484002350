<template>
  <!-- <CBox bg="bgMain" height="full">
    <CBox ml="60px" mr="60px" pt="20px" pl="10px" pr="10px" pb="5px"> -->
  <CBox my="20px">
      <!-- <CHeading color="fontMain" size="2xl">Dashboard Premium</CHeading>
      <CDivider borderColor="dividerColor" /> -->
      <!-- <CBox bg="white" w="100%" d="flex" my="15px">
        <BreadcrumbUpload />
      </CBox> -->
      <CGrid templateColumns="7fr 3fr" :gap="6" autoRows="min-content">
        <CBox w="100%" h="min-content" bg="white" border="1px" borderColor="borderBox">
          <!-- <CDivider borderColor="borderBox" border="1.5px" /> -->
          <!-- <CBox mx="32px" my="20px"> -->
            <CBox v-if="!user.emp_id">
              <CBox h="20px" />
              <Loading v-if="!user.emp_id"/>
            </CBox>
            <CBox v-if="user.emp_id" mx="32px" my="20px">
              <CText fontWeight="bold" color="fontMain" my="10px">Profil Web CV</CText>
              <CBox maxH="calc(100vh - 310px)" overflowY="scroll">
                <CFormControl>
                  <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                    <CFormLabel color="fontMain">Nama</CFormLabel>
                    <CInput type="text" placeholder="Tuliskan nama anda disini" v-model="form.name"></CInput>
                  </CGrid>
                </CFormControl>
                <CFormControl mt="10px">
                  <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                    <CFormLabel color="fontMain">Profesi</CFormLabel>
                    <CInput type="text" placeholder="Tuliskan profesi anda disini" v-model="form.profession"></CInput>
                  </CGrid>
                </CFormControl>
                <CFormControl mt="10px">
                  <CGrid templateColumns="1fr 4fr" :gap="6" w="100%">
                    <CFormLabel color="fontMain">Deskripsi</CFormLabel>
                    <CTextarea placeholder="Tambahkan deskripsi singkat, hobi, atau kepribadian anda" v-model="form.description" :value="form.description" h="200px"></CTextarea>
                  </CGrid>
                </CFormControl>
              </CBox>
            </CBox>
            <!-- <CDivider borderColor="borderBox" border="1.5px" />
            <CBox v-if="user.emp_id" mx="32px" my="20px">
              <CText fontWeight="bold" color="fontMain" my="10px">Link Download CV PDF</CText>
              <CText fontSize="12px" color="fontInformation">Pilih Desain CV PDF yang kamu inginkan. Pengunjung akan dengan mudah mendownload CV kamu sesuai dengan desain yang telah kamu pilih.</CText>
              <CSimpleGrid :columns="5" :spacing="3" mt="10px">
                <CBox border="1px" borderColor="borderBox" v-for="(item, index) in currentCVList" :key="index">
                  <CImage :src="require(`@/assets/cv/cv-${item.id}.png`)" objectFit="cover" w="100%" h="150px" />
                  <CBox align="center" mx="auto" my="10px">
                    <CText fontWeight="bold" align="center" mb="10px" color="fontMain">{{ item.name }}</CText>
                    <ButtonSecondary2 v-if="item.id == user.cv_design_converted" label="Dipilih" size="sm" />
                    <ButtonBlue v-else label="Pilih" size="sm" @click.native="chooseDesign(item.id)" />
                  </CBox>
                </CBox>
              </CSimpleGrid>
            </CBox> -->
            <CDivider borderColor="borderBox" border="1.5px" v-if="user.emp_id" />
            <CBox align="right" m="20px" v-if="user.emp_id">
              <ButtonBlue label="Lanjutkan" @click.native="onSubmit()" :disabled="disabledButton" />
            </CBox>
          <!-- </CBox> -->
        </CBox>
        <CBox>
          <CBox mx="10px" w="100%" bg="white" border="1px" borderColor="borderBox" style="position: relative;">
            <CBox class="coba" borderLeft="1px" borderBottom="1px" borderColor="borderBox" style="position: absolute; top: 20px;" h="10px" />
            <CBox my="20px" mx="15px">
              <CText fontWeight="bold" color="fontInformation">Profil Web CV</CText>
              <CText color="fontInformation" fontSize="14px">
                Tuliskan profile meliputi Nama terang, profesi dan deskripsi singkat yang menarik perihal hobi, atau kepribadian anda.
              </CText>
              <CImage :src="require('@/assets/img/desain-cv-ilustration.png')" />
            </CBox>
          </CBox>
        </CBox>
      </CGrid>
      <!-- <WizardFooter @onSubmit="onSubmit()" :disabledButton="disabledButton" /> -->
    <!-- </CBox> -->
  </CBox>
</template>

<script>
import { GET_USER, UPDATE_USER_EMPLOYEE, UPDATE_CV } from "@/store/auth.module"
import ButtonBlue from "@/components/button/ButtonBlue.vue"
// import ButtonSecondary2 from "@/components/button/ButtonSecondary2.vue"
// import BreadcrumbUpload from "@/components/breadcrumb/BreadcrumbUpload.vue"
import Swal from "sweetalert2";
import Loading from "@/components/Loading.vue";
// import WizardFooter from "@/components/footer/WizardFooter.vue";

export default {
  metaInfo: {
    title: "Pilih Desain",
    titleTemplate: "%s - Gethired.id"
  },
  components: {
    ButtonBlue,
    // WizardFooter,
    // ButtonSecondary2,
    // BreadcrumbUpload,
    Loading
  },
  data() {
    return {
      user: {},
      disabledButton: false,
      form: {
        name: "",
        profession: "",
        description: ""
      }
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    currentCVList() {
      return this.$store.getters.currentCVList
    }
  },
  methods: {
    async getData() {
      this.disabledButton = true;
      // setTimeout(async () => {
        await this.$store.dispatch(GET_USER)
        this.disabledButton = false;
        const user = this.$store.getters.currentUser;
        this.user = user;
        this.form.name = user.emp_full_name;
        this.form.profession = user.emp_professional_statement;
        this.form.description = user.emp_description;
      // }, 100);
    },
    chooseDesign(slug) {
      this.user.cv_design_converted = slug;
    },
    onSubmit() {
      this.disabledButton = true;

      this.$store
        .dispatch(UPDATE_CV, this.user.cv_design_converted)
        .then(() => {
          this.$store
            .dispatch(UPDATE_USER_EMPLOYEE, {
              id: this.user.emp_id,
              data: {
                emp_full_name: this.form.name,
                emp_professional_statement: this.form.profession,
                emp_description: this.form.description,
                cv_design: this.user.cv_design_converted
              }
            })
            .then(() => {
              this.disabledButton = false;
              this.$router.push({ name: "Dashboard Upload Photo" })
            })
            .catch((err) => {
              this.disabledButton = false;
              let errData = err.message;
              if (err.response && err.response.data && err.response.data.message)
                errData = err.response.data.message;
              Swal.fire({
                title: "Oops . .",
                text: errData,
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false
              });
            });
        })
        .catch((err) => {
          this.disabledButton = false;
          let errData = err.message;
          if (err.response && err.response.data && err.response.data.message)
            errData = err.response.data.message;
          Swal.fire({
            title: "Oops . .",
            text: errData,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        });
    },
  }
}
</script>

<style scoped>
.coba {
  content: "";
  left: calc(-0.5rem);
  transform: rotate(45deg);
  background-color: rgb(255, 255, 255);
  display: block;
  width: 1rem;
  height: 1rem;
}
</style>
